<template>
  <div>
    <div style="margin-bottom: 10px; display: flex; flex-direction: row; align-items: center;">
      <button v-show="hasResource('020402')" v-on:click="add()" class="btn btn-white btn-default btn-round" style="margin-right: 10px;">
        <i class="ace-icon fa fa-edit"></i>
        新增
      </button>
      <button v-on:click="list(1)" class="btn btn-white btn-default btn-round" style="margin-right: 16px;">
        <i class="ace-icon fa fa-refresh"></i>
        刷新
      </button>
      姓名：<input v-model="name" class="form-control" style="width: 100px; margin-right: 10px;">
      <button v-on:click="list(1)" class="btn btn-white btn-default btn-round">
        <i class="ace-icon fa fa-refresh"></i>
        检索
      </button>
    </div>

    <table id="simple-table" class="table  table-bordered table-hover">
      <thead>
      <tr>
        <th>id</th>
        <th>原数据ID+姓名</th>
        <th>姓名</th>
        <th>头像</th>
        <th>所属科室</th>
        <th>职称</th>
        <th>是否推荐</th>
        <th>是否可挂号</th>
        <th>排序（数字小靠前）</th>
        <th>操作</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="item in doctor_items">
        <td>{{item.id}}</td>
        <td>{{item.id_and_name_and_section}}</td>
        <td>{{item.name}}</td>
        <td>
          <img :src="item.avatar" style="width: 100px;">
        </td>
        <td>{{item.section_name}}</td>
        <td>{{item.positional}}</td>
        <td>
          <p v-if="item.is_recommend == 0">不推荐</p>
          <p v-else>推荐</p>
        </td>
        <td>
          <p v-if="item.can_order == 0">否</p>
          <p v-else>是</p>
        </td>
        <td>{{item.sort}}</td>
        <td>
          <div class="hidden-sm hidden-xs btn-group">
            <button v-show="hasResource('020402')" v-on:click="edit(item)" class="btn btn-xs btn-info">
              <i class="ace-icon fa fa-pencil bigger-120"></i>
            </button>
            <button v-show="hasResource('020403')" v-on:click="del(item.id)" class="btn btn-xs btn-danger">
              <i class="ace-icon fa fa-trash-o bigger-120"></i>
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <pagination ref="pagination" v-bind:list="list" v-bind:itemCount="8"></pagination>

    <div id="form-modal" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" v-if="doctor_item.id == null">新增医生</h4>
            <h4 class="modal-title" v-else>编辑医生</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal">
              <div class="form-group">
                <label class="col-sm-2 control-label">姓名</label>
                <div class="col-sm-10">
                  <div style="display: flex; flex-direction: row;">
                    <input v-model="doctor_item.name" class="form-control" style="width: 200px;" id="doctorName" v-on:input ="suaiXuanSection">
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">原数据名称</label>
                <div class="col-sm-10">
                  <select v-model="doctor_item.original_id" class="form-control" @change="hasSelected(doctor_item.original_id)">
                    <option v-for="o in staff_items" v-bind:value="o.ygdm">{{o.id_and_name_and_section}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">头像</label>
                <div class="col-sm-10">
                  <file v-bind:input-id="'content-file-upload'"
                        v-bind:text="'上传文件'"
                        v-bind:suffixs="['jpg', 'jpeg', 'png', 'gif']"
                        v-bind:use="FILE_USE.COURSE.key"
                        v-bind:after-upload="afterUploadContentFile"></file>
                  <div v-show="doctor_item.avatar" class="row">
                    <div class="col-md-6">
                      <img v-bind:src="doctor_item.avatar" class="img-responsive">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">职称</label>
                <div class="col-sm-10">
                  <input v-model="doctor_item.positional" class="form-control">
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">医生擅长</label>
                <div class="col-sm-10">
                  <textarea v-model="doctor_item.be_good_at" class="form-control"></textarea>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">个人履历</label>
                <div class="col-sm-10">
                  <textarea v-model="doctor_item.personal_details" class="form-control"></textarea>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">是否推荐</label>
                <div class="col-sm-10">
                  <label>
                    不推荐
                    <input name="is_recommend" v-model="doctor_item.is_recommend" type="radio" value="0" />
                  </label>
                  <label style="margin-left: 20px;">
                    推荐
                    <input name="is_recommend" v-model="doctor_item.is_recommend" type="radio" value="1" />
                  </label>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">是否可挂号</label>
                <div class="col-sm-10">
                  <label>
                    是
                    <input name="can_order" v-model="doctor_item.can_order" type="radio" value="1" />
                  </label>
                  <label style="margin-left: 20px;">
                    否
                    <input name="can_order" v-model="doctor_item.can_order" type="radio" value="0" />
                  </label>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">排序</label>
                <div class="col-sm-10">
                  <input v-model="doctor_item.sort" class="form-control">
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
            <button v-on:click="save()" type="button" class="btn btn-primary">保存</button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
  </div>
</template>

<script>
import Pagination from "../../components/pagination";
import File from "../../components/file";
export default {
  components: {File, Pagination},
  name: "business-doctor",
  data: function() {
    return {
      FILE_USE: FILE_USE,
      doctor_item: {
        is_recommend: '0',
        can_order: '1'
      },
      doctor_items: [],
      section_items: [],
      staff_items: []
    }
  },
  mounted: function() {
    let _this = this;
    _this.$refs.pagination.size = 5;
    _this.list(1);
    // _this.sectionList();
    _this.staffList();
    // sidebar激活样式方法一
    // this.$parent.activeSidebar("system-user-sidebar");
  },
  methods: {
    /**
     * 查找是否有权限
     * @param id
     */
    hasResource(id) {
      return Tool.hasResource(id);
    },

    /**
     * 点击【新增】
     */
    add() {
      let _this = this;
      _this.doctor_item = {};
      $("#form-modal").modal("show");
    },

    /**
     * 点击【编辑】
     */
    edit(doctor_item) {
      console.log("doctor_item:", doctor_item);
      let _this = this;
      _this.doctor_item = $.extend({}, doctor_item);

      $("#form-modal").modal("show");
    },

    /**
     * 列表查询
     */
    list(page) {
      let _this = this;
      Loading.show();
      _this.$ajax.post(process.env.VUE_APP_SERVER + 'doctor/list',{
        page: page,
        size: _this.$refs.pagination.size,
        name: _this.name
      }).then((response)=>{
        console.log("医生列表查询结果：", response);
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          _this.doctor_items = resp.content.list;
          _this.$refs.pagination.render(page, resp.content.total);
        }
      })
    },
    suaiXuanSection() {
      let _this = this;
      let keyword = "null"
      if (_this.doctor_item.name !== "") {
        keyword = _this.doctor_item.name
        Loading.show();
        _this.$ajax.get(process.env.VUE_APP_SERVER + 'ms_ygdm/list_by_key/' + _this.doctor_item.name).then((response)=>{
          Loading.hide();
          let resp = response.data;
          if (resp.success) {
            _this.staff_items = resp.content;
          }
        })
      } else {
        _this.staffList();
      }
    },
    // 选择后，改变医生姓名
    hasSelected (original_id) {
      let _this = this;
      Loading.show();
      _this.$ajax.get(process.env.VUE_APP_SERVER + 'ms_ygdm/get_by_id/' + original_id).then((response)=>{
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          console.log("lallala", resp.content)
          _this.doctor_item.name = resp.content.ygxm;
        }
      })
    },

    /**
     * 科室列表
     */
    // sectionList() {
    //   let _this = this;
    //   Loading.show();
    //   _this.$ajax.get(process.env.VUE_APP_SERVER + 'ms_ghk/list').then((response)=>{
    //     console.log("科室列表查询结果：", response);
    //     Loading.hide();
    //     let resp = response.data;
    //     if (resp.success) {
    //       _this.section_items = resp.content;
    //     }
    //   })
    // },
    /**（对接原数据库）
     * 员工列表
     */
    staffList() {
      let _this = this;
      Loading.show();
      _this.$ajax.get(process.env.VUE_APP_SERVER + 'ms_ygdm/list').then((response)=>{
        console.log("员工列表查询结果：", response);
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          _this.staff_items = resp.content;
        }
      })
    },

    afterUploadContentFile (response) {
      console.log("开始保存文件记录: ", response);
      this.doctor_item.avatar = response[0].path;
    },

    /**
     * 点击【保存】
     */
    save() {
      let _this = this;
      // 保存校验
      if (1 != 1
          || !Validator.require(_this.doctor_item.name, "姓名")
      ) {
        return;
      }
      Loading.show();
      _this.$ajax.post(process.env.VUE_APP_SERVER + 'doctor/save', _this.doctor_item).then((response)=>{
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          $("#form-modal").modal("hide");
          _this.list(1);
          _this.staffList();
          Toast.success("保存成功！");
        } else {
          Toast.warning(resp.message)
        }
      })
    },

    /**
     * 点击【删除】
     */
    del(id) {
      let _this = this;
      Confirm.show("删除医生后不可恢复，确认删除？", function () {
        Loading.show();
        _this.$ajax.delete(process.env.VUE_APP_SERVER + 'doctor/delete/' + id).then((response)=>{
          Loading.hide();
          let resp = response.data;
          if (resp.success) {
            _this.list(1);
            Toast.success("删除成功！");
          }
        })
      });
    },
  }
}
</script>
