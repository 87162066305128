<template>
  <div>
    <div style="margin-bottom: 10px; display: flex; flex-direction: row; align-items: center;">
      <button v-on:click="refreshData" class="btn btn-white btn-default btn-round" style="margin-right: 16px;">
        <i class="ace-icon fa fa-refresh"></i>
        刷新
      </button>
      姓名：<input v-model="username" class="form-control" style="width: 100px; margin-right: 10px;">
      手机号：<input v-model="phone" class="form-control" style="width: 100px; margin-right: 10px;">
      <button v-on:click="list(1)" class="btn btn-white btn-default btn-round">
        <i class="ace-icon fa fa-refresh"></i>
        检索
      </button>
    </div>

    <table id="simple-table" class="table  table-bordered table-hover">
      <thead>
      <tr>
        <th>id</th>
        <th>姓名</th>
        <th>手机号</th>
        <th>微信openid</th>
        <th>注册时间</th>
        <th>操作</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="user in users">
        <td>{{user.id}}</td>
        <td>{{user.username}}</td>
        <td>{{user.phone}}</td>
        <td>{{user.openid}}</td>
        <td>{{user.create_time}}</td>
        <td>
        </td>
      </tr>
      </tbody>
    </table>
    <pagination ref="pagination" v-bind:list="list" v-bind:itemCount="8"></pagination>
  </div>
</template>

<script>
  import Pagination from "../../../components/pagination";
  export default {
    components: {Pagination},
    name: "food-user",
    data: function() {
      return {
        user: {},
        users: [],
        username: '',
        phone: '',
        shops: [],
        shop_user: {
          user_id: -1,
          status: 0
        },
        shopCheckList: []
      }
    },
    mounted: function() {
      let _this = this;
      _this.$refs.pagination.size = 20;
      _this.list(1);
      // _this.shopList();
      // sidebar激活样式方法一
      // this.$parent.activeSidebar("system-user-sidebar");
    },
    methods: {
      /**
       * 查找是否有权限
       * @param id
       */
      hasResource(id) {
        return Tool.hasResource(id);
      },

      // shopList() {
      //   let _this = this;
      //   Loading.show();
      //   _this.$ajax.get(process.env.VUE_APP_SERVER + 'yj/list').then((response)=>{
      //     console.log("列表查询结果：", response);
      //     Loading.hide();
      //     let resp = response.data;
      //     if (resp.success) {
      //       _this.shops = resp.content;
      //     }
      //   })
      // },

      // 更新数据
      refreshData () {
        this.list(1);
        // this.shopList();
      },

      /**
       * 列表查询
       */
      list(page) {
        let _this = this;
        Loading.show();
        _this.$ajax.post(process.env.VUE_APP_SERVER + 'food_user/list', {
          page: page,
          size: _this.$refs.pagination.size,
          username: _this.username,
          phone: _this.phone
        }).then((response)=>{
          // console.log("列表查询结果：", response);
          Loading.hide();
          let resp = response.data;
          if (resp.success) {
            _this.users = resp.content.list;
            _this.$refs.pagination.render(page, resp.content.total);
          }
        })
      },

      setShopAdmin (user) {
        let _this = this;
        _this.user = $.extend({}, user);
        Loading.show();
        _this.$ajax.get(process.env.VUE_APP_SERVER + 'food_user/get_food_admin_by_user_id/' + _this.user.id).then((response)=>{
          Loading.hide();
          let resp = response.data;
          if (resp.success) {
            if (resp.content != null) {
              _this.shop_user = resp.content;
              _this.shopCheckList = resp.content.shop_ids;
            }
          }
        })
        $("#form-modal").modal("show");
      },

      save () {
        console.log(this.shopCheckList)
        let _this = this;
        Loading.show();
        _this.shop_user.user_id = _this.user.id;
        _this.shop_user.shop_ids = this.shopCheckList
        _this.$ajax.post(process.env.VUE_APP_SERVER + 'food_user/set_food_admin', _this.shop_user).then((response)=>{
          Loading.hide();
          this.shop_user = {
            user_id: -1,
            status: 0
          }
          let resp = response.data;
          $("#form-modal").modal("hide");
          _this.list(1);
          _this.shopList();
          if (resp.success) {
            Toast.success("保存成功！");
          } else {
            Toast.success("保存失败！");
          }
        })
      },

      listShopAdmin (page) {
        let _this = this;
        Loading.show();
        _this.$ajax.post(process.env.VUE_APP_SERVER + 'food_user/food_admin_list', {
          page: page,
          size: _this.$refs.pagination.size
        }).then((response)=>{
          console.log("列表查询结果：", response);
          Loading.hide();
          let resp = response.data;
          if (resp.success) {
            _this.users = resp.content.list;
            _this.$refs.pagination.render(page, resp.content.total);
          }
        })
      }
    }
  }
</script>
