<template>
  <div>
    <p>
      <button v-on:click="list()" class="btn btn-white btn-default btn-round" style="margin-right: 10px;">
        <i class="ace-icon fa fa-refresh"></i>
        全部列表
      </button>
      <button v-on:click="getByStatus(1)" class="btn btn-white btn-default btn-round" style="margin-right: 10px;">
        <i class="ace-icon fa fa-refresh"></i>
        启用列表
      </button>
      <button v-on:click="getByStatus(0)" class="btn btn-white btn-default btn-round">
        <i class="ace-icon fa fa-refresh"></i>
        禁用列表
      </button>
    </p>

    <table id="simple-table" class="table  table-bordered table-hover">
      <thead>
      <tr>
        <th>ID</th>
<!--        <th>门诊科室ID</th>-->
        <th>科室名称</th>
        <th>所属科室分类</th>
        <th>状态</th>
        <th>排序(数字小靠前)</th>
        <th>操作</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="item in section_items">
        <td>{{item.ms_ghk_id}}</td>
<!--        <td>{{item.mzks_id}}</td>-->
        <td>{{item.name}}</td>
        <td>
          <div v-if="!item.category_name">未分配</div>
          <div v-else>{{ item.category_name }}</div>
        </td>
        <td>
          <div v-if="item.status === 1">启用</div>
          <div v-else-if="item.status === 0">禁用</div>
          <div v-else>未知</div>
        </td>
        <td>{{item.sort}}</td>
        <td>
          <div class="hidden-sm hidden-xs btn-group">
            <button v-show="hasResource('020302')" v-on:click="edit(item)" class="btn btn-xs btn-info">
              <i class="ace-icon fa fa-pencil bigger-120"></i>
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

    <div id="form-modal" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" v-if="section_item.id == null">新增科室</h4>
            <h4 class="modal-title" v-else>编辑科室</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal">
              <div class="form-group">
                <label class="col-sm-2 control-label">名称</label>
                <div class="col-sm-10">
                  <input disabled v-model="section_item.name" class="form-control">
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">排序</label>
                <div class="col-sm-10">
                  <input v-model="section_item.sort" class="form-control">
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">所属科室分类</label>
                <div class="col-sm-10">
                  <select v-model="section_item.category_id" class="form-control">
                    <option v-for="o in category_items" v-bind:value="o.id">{{o.name}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">状态</label>
                <div class="col-sm-10" style="margin-top: 6px;">
                  <label>
                    禁用
                    <input name="is_recommend" v-model="section_item.status" type="radio" value="0" />
                  </label>
                  <label style="margin-left: 20px;">
                    启用
                    <input name="is_recommend" v-model="section_item.status" type="radio" value="1" />
                  </label>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
            <button v-on:click="save()" type="button" class="btn btn-primary">保存</button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
  </div>
</template>

<script>
import File from "../../components/file";
export default {
  components: {File},
  name: "business-section",
  data: function() {
    return {
      FILE_USE: FILE_USE,
      section_item: {},
      section_items: [],
      category_items: []
    }
  },
  mounted: function() {
    let _this = this;
    _this.list();
    _this.category_list()
    // sidebar激活样式方法一
    // this.$parent.activeSidebar("system-user-sidebar");
  },
  methods: {
    /**
     * 查找是否有权限
     * @param id
     */
    hasResource(id) {
      return Tool.hasResource(id);
    },

    /**
     * 点击【编辑】
     */
    edit(section_item) {
      console.log("section_item:", section_item);
      let _this = this;
      _this.section_item = $.extend({}, section_item);
      $("#form-modal").modal("show");
    },

    /**
     * 列表查询
     */
    list() {
      let _this = this;
      Loading.show();
      _this.$ajax.get(process.env.VUE_APP_SERVER + 'section/list').then((response)=>{
        console.log("科室列表查询结果：", response);
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          _this.section_items = resp.content;
        }
      })
    },

    /**
     * 列表查询
     */
    category_list() {
      let _this = this;
      Loading.show();
      _this.$ajax.get(process.env.VUE_APP_SERVER + 'category/list').then((response)=>{
        console.log("列表查询结果：", response);
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          _this.category_items = resp.content;
        }
      })
    },

    /**
     * 点击【保存】
     */
    save() {
      let _this = this;
      // 保存校验
      if (1 != 1
          || !Validator.require(_this.section_item.name, "名称不能为空")
      ) {
        return;
      }
      Loading.show();
      _this.$ajax.post(process.env.VUE_APP_SERVER + 'section/save', _this.section_item).then((response)=>{
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          $("#form-modal").modal("hide");
          _this.list(1);
          Toast.success("保存成功！");
        } else {
          Toast.warning(resp.message)
        }
      })
    },

    getByStatus (status) {
      let _this = this;
      Loading.show();
      _this.$ajax.get(process.env.VUE_APP_SERVER + 'section/list_by_status/' + status).then((response)=>{
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          _this.section_items = resp.content;
        }
      })
    }
  }
}
</script>
