<template>
  <div>
    <p>
      <button v-on:click="list()" class="btn btn-white btn-default btn-round">
        <i class="ace-icon fa fa-refresh"></i>
        刷新
      </button>
    </p>

    <table id="simple-table" class="table  table-bordered table-hover">
      <thead>
      <tr>
        <th>id</th>
        <th>电话</th>
        <th>姓名</th>
        <th>信息</th>
        <th>提交时间</th>
        <th>备注</th>
        <th>操作</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="item in contact_items">
        <td>{{item.id}}</td>
        <td>{{item.phone}}</td>
        <td>{{item.name}}</td>
        <td>{{item.message}}</td>
        <td>{{item.create_time}}</td>
        <td>{{item.remarks}}</td>
        <td>
          <div class="hidden-sm hidden-xs btn-group">
            <button v-show="hasResource('020602')" v-on:click="edit(item)" class="btn btn-xs btn-info">
              <i class="ace-icon fa fa-pencil bigger-120"></i>
            </button>
            <button v-show="hasResource('020603')" v-on:click="del(item.id)" class="btn btn-xs btn-danger">
              <i class="ace-icon fa fa-trash-o bigger-120"></i>
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

    <pagination ref="pagination" v-bind:list="list" v-bind:itemCount="8"></pagination>

    <div id="form-modal" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">联系我们-详情</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal">
              <div class="form-group">
                <label class="col-sm-2 control-label">电话</label>
                <div class="col-sm-10">
                  <input disabled v-model="contact_item.phone" class="form-control">
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">姓名</label>
                <div class="col-sm-10">
                  <input disabled v-model="contact_item.name" class="form-control">
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">信息</label>
                <div class="col-sm-10">
                  <textarea disabled v-model="contact_item.message" class="form-control"></textarea>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">备注</label>
                <div class="col-sm-10">
                  <textarea v-model="contact_item.remarks" class="form-control"></textarea>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
            <button v-on:click="save()" type="button" class="btn btn-primary">保存</button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
  </div>
</template>

<script>
import File from "../../components/file";
import Pagination from "../../components/pagination";

export default {
  components: {File, Pagination},
  name: "business-contact",
  data: function() {
    return {
      FILE_USE: FILE_USE,
      contact_item: {},
      contact_items: [],
    }
  },
  mounted: function() {
    let _this = this;
    _this.$refs.pagination.size = 20;
    _this.list(1);
    // sidebar激活样式方法一
    // this.$parent.activeSidebar("system-user-sidebar");
  },
  methods: {
    /**
     * 查找是否有权限
     * @param id
     */
    hasResource(id) {
      return Tool.hasResource(id);
    },

    /**
     * 点击【编辑】
     */
    edit(contact_item) {
      console.log("contact_item:", contact_item);
      let _this = this;
      _this.contact_item = $.extend({}, contact_item);
      $("#form-modal").modal("show");
    },

    /**
     * 列表查询
     */
    list(page) {
      let _this = this;
      Loading.show();
      _this.$ajax.post(process.env.VUE_APP_SERVER + 'contact_us/list', {
        page: page,
        size: _this.$refs.pagination.size,
      }).then((response)=>{
        console.log("列表查询结果：", response);
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          _this.contact_items = resp.content.list;
          _this.$refs.pagination.render(page, resp.content.total);
        }
      })
    },

    /**
     * 点击【保存】
     */
    save() {
      let _this = this;
      Loading.show();
      _this.$ajax.post(process.env.VUE_APP_SERVER + 'contact_us/save', _this.contact_item).then((response)=>{
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          $("#form-modal").modal("hide");
          _this.list(1);
          Toast.success("保存成功！");
        } else {
          Toast.warning(resp.message)
        }
      })
    },

    /**
     * 点击【删除】
     */
    del(id) {
      let _this = this;
      Confirm.show("删除后不可恢复，确认删除？", function () {
        Loading.show();
        _this.$ajax.delete(process.env.VUE_APP_SERVER + 'contact_us/delete/' + id).then((response)=>{
          Loading.hide();
          let resp = response.data;
          if (resp.success) {
            _this.list(1);
            Toast.success("删除成功！");
          }
        })
      });
    },
  }
}
</script>
