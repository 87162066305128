<template>
  <div>
    <p>
      <button v-on:click="list()" class="btn btn-white btn-default btn-round">
        <i class="ace-icon fa fa-refresh"></i>
        刷新
      </button>
    </p>

    <table id="simple-table" class="table  table-bordered table-hover">
      <thead>
      <tr>
        <th>id</th>
        <th class="detail-col">展开</th>
        <th>门店名称</th>
        <th>是否配置了排班</th>
      </tr>
      </thead>

      <tbody>
        <template v-for="(item, index) in shop_items">
          <tr>
            <td>{{item.id}}</td>
            <td class="el-col-2" @click="loadDetail(index, item.id)">
              <div class="action-buttons" style="display: flex; justify-content: center;">
                <a href="#" class="green bigger-140 show-details-btn" title="显示排班详情">
                  <i class="ace-icon fa fa-angle-double-down"></i>
                  <span class="sr-only">Details</span>
                </a>
              </div>
            </td>
            <td>{{item.name}}</td>
            <td v-if="item.has_arrange" style="color: green;">已配置</td>
            <td v-else><span style="color: red;">未配置</span></td>
          </tr>
          <tr class="" style="background-color: #F1F6F8" v-show="index === currentActive">
            <td colspan="8">
              <div class="table-detail">
                <div class="row">
                  <div class="col-xs-12 col-sm-5">
                    <div class="space visible-xs"></div>
                    <div class="profile-user-info profile-user-info-striped">
                      <div class="profile-info-row" v-for="(item2, index2) in arrangeSbSimpleList">
                        <div class="profile-info-name" style="width: 80px"> {{ index2 + 1 }} </div>
                        <div class="profile-info-value">
                          <span>{{ item2.week }} {{ item2.monthDay }}</span>
                          <span>{{ item2.hasStr }}</span>
                          <span>已预约 {{ item2.dayOrderedCount }} 次</span>
                          <span>剩余 {{ item2.dayUnOrderedCount }} 次</span>
                          <span>共 {{ item2.dayTotalCount }} 次</span>
                        </div>
                        <div v-if="item2.is_unusual === 1">
                          <span style="color: red;">数据异常</span>
                          <el-button type="primary" plain size="small" class="el-button" style="margin-left: 6px;" @click="reCreateDay(item, index2)">重新生成</el-button>
                        </div>
                        <div v-else>
                          <el-button type="primary" plain size="small" class="el-button" style="margin-left: 6px;" @click="deleteDay(item, index2)">删除</el-button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-5">
                    <div class="space visible-xs"></div>
                    <h4 class="header blue lighter less-margin">排班配置</h4>
                    <div class="space-6"></div>
                    <form class="form-horizontal">
                      <div class="form-group">
                        <label class="col-sm-2 control-label">时间间隔设置</label>
                        <div class="col-sm-10">
                          <el-input-number v-model="arrangeSettingSbData.time_space" :step="1" step-strictly></el-input-number>
                          <span style="margin-left: 8px;">分钟</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="col-sm-2 control-label">上午时间设置</label>
                        <div class="col-sm-10">
                          <el-time-select
                              placeholder="起始时间"
                              v-model="arrangeSettingSbData.morning_start_time"
                              :picker-options="{
                                start: '07:00',
                                step: '01:00',
                                end: '22:00'
                              }">
                          </el-time-select>
                          <el-time-select
                              style="margin-left: 8px;"
                              placeholder="结束时间"
                              v-model="arrangeSettingSbData.morning_end_time"
                              :picker-options="{
                                start: '07:00',
                                step: '01:00',
                                end: '13:00',
                                minTime: arrangeSettingSbData.morning_start_time
                              }">
                          </el-time-select>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="col-sm-2 control-label">下午时间设置</label>
                        <div class="col-sm-10">
                          <el-time-select
                              placeholder="起始时间"
                              v-model="arrangeSettingSbData.afternoon_start_time"
                              :picker-options="{
                                start: '13:00',
                                step: '01:00',
                                end: '22:00'
                              }">
                          </el-time-select>
                          <el-time-select
                              style="margin-left: 8px;"
                              placeholder="结束时间"
                              v-model="arrangeSettingSbData.afternoon_end_time"
                              :picker-options="{
                                start: '13:00',
                                step: '01:00',
                                end: '22:00',
                                minTime: arrangeSettingSbData.afternoon_start_time
                              }">
                          </el-time-select>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="col-sm-2 control-label">每时段可预约次数</label>
                        <div class="col-sm-10">
                          <el-input-number v-model="arrangeSettingSbData.num" :step="1" step-strictly></el-input-number>
                          <span style="margin-left: 8px;">次</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="col-sm-2 control-label">可预约天数</label>
                        <div class="col-sm-10">
                          <el-input-number v-model="arrangeSettingSbData.can_order_day" :step="1" step-strictly></el-input-number>
                          <span style="margin-left: 8px;">天</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="col-sm-2 control-label">一周上班时间</label>
                        <div class="col-sm-10">
                          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                          <div style="margin: 15px 0;"></div>
                          <el-checkbox-group v-model="checkedWeekDays" @change="handleCheckedCitiesChange">
                            <el-checkbox v-for="item in week_day_list" :label="item.id" :key="item.id">{{item.name}}</el-checkbox>
                          </el-checkbox-group>
                        </div>
                      </div>

                      <div class="hr hr-dotted"></div>
                      <div>
                        <span style="color: red;">注意：如果不是第一次配置，那么该次配置只会影响到N天以后的排班，N天内的请在左侧的对应天中调整。当天的调整不能改时间间隔和上下班的时间段，所以，第一次请慎重配置。</span>
                      </div>
                      <div class="hr hr-dotted"></div>
                      <button @click="saveSetting(item.id)" class="pull-left btn btn-success btn-round" type="button">
                        保存设置
                        <i class="ace-icon fa fa-save bigger-110"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div id="form-modal" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">【{{ shop_item.name }}】【{{ arrange_sb_item.monthDay }}】排班调整</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal">
              <div class="form-group">
                <label class="col-sm-2 control-label">名称</label>
                <div class="col-sm-10">
                  <input v-model="shop_item.name" class="form-control">
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">排序</label>
                <div class="col-sm-10">
                  <input v-model="shop_item.sort" class="form-control">
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
            <button v-on:click="save()" type="button" class="btn btn-primary">保存</button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
  </div>
</template>

<script>
export default {
  name: "yj-arrange",
  data: function() {
    return {
      shop_item: {},
      shop_items: [],
      arrange_sb_item: {},
      arrange_sb_index: 0,
      arrange_sb_items: [],
      currentActive:-1,
      checkAll: false,
      checkedWeekDays: [],
      isIndeterminate: false,
      all_week_day_checked: [1,2,3,4,5,6,7,8,9,10,11,12,13,14],
      week_day_list: [
        {
          "id": 1,
          "name": "周一上午"
        }, {
          "id": 2,
          "name": "周一下午"
        }, {
          "id": 3,
          "name": "周二上午"
        }, {
          "id": 4,
          "name": "周二下午"
        }, {
          "id": 5,
          "name": "周三上午"
        }, {
          "id": 6,
          "name": "周三下午"
        }, {
          "id": 7,
          "name": "周四上午"
        }, {
          "id": 8,
          "name": "周四下午"
        }, {
          "id": 9,
          "name": "周五上午"
        }, {
          "id": 10,
          "name": "周五下午"
        }, {
          "id": 11,
          "name": "周六上午"
        }, {
          "id": 12,
          "name": "周六下午"
        }, {
          "id": 13,
          "name": "周日上午"
        }, {
          "id": 14,
          "name": "周日下午"
        }],
      arrangeSettingSbData: {
        time_space: "",
        morning_start_time: "",
        morning_end_time: "",
        afternoon_start_time: "",
        afternoon_end_time: "",
      },
      arrangeSbSimpleList: []
    }
  },
  mounted: function() {
    let _this = this;
    _this.list();

    // sidebar激活样式方法一
    // this.$parent.activeSidebar("system-user-sidebar");
  },
  methods: {
    /**
     * 查找是否有权限
     * @param id
     */
    hasResource(id) {
      return Tool.hasResource(id);
    },

    /**
     * 列表查询
     */
    list() {
      let _this = this;
      Loading.show();
      _this.$ajax.get(process.env.VUE_APP_SERVER + 'yj/list').then((response)=>{
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          _this.shop_items = resp.content;
        }
      })

    },

    loadDetail(index, id) {
      const _this = this;
      if(this.currentActive === index){
        this.currentActive = -1;
      }else{
        this.currentActive = index;
      }
      _this.currentId = id
      _this.getSettingByShopId(id);
      _this.getArrangeSimpleListByShopId(id)
    },

    getSettingByShopId (shopId) {
      const _this = this;
      Loading.show();
      _this.$ajax.get(process.env.VUE_APP_SERVER + 'arrange_yj/get_setting_by_yj_id/' + shopId).then((response)=>{
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          console.log("获取配置结果：", resp.content);
          if (resp.content != null) {
            _this.arrangeSettingSbData = resp.content;
            // TODO:: 其他参数设置
            _this.checkedWeekDays = resp.content.checked_week_days
            let checkedCount = _this.checkedWeekDays.length;
            this.checkAll = checkedCount === this.all_week_day_checked.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.all_week_day_checked.length;
          }
        } else {
          this.arrangeSettingSbData = {
            time_space: "",
            morning_start_time: "",
            morning_end_time: "",
            afternoon_start_time: "",
            afternoon_end_time: "",
          }
          _this.checkedWeekDays = [];
          this.checkAll = false;
          this.isIndeterminate = false;
        }
      })
    },

    getArrangeSimpleListByShopId (shopId) {
      const _this = this;
      Loading.show();
      _this.$ajax.get(process.env.VUE_APP_SERVER + 'arrange_yj/get_arrange_yj_list/' + shopId).then((response)=>{
        Loading.hide();
        _this.arrangeSbSimpleList = response.data.content;
      })
    },

    reCreateDay (item, index2) {
      const _this = this;
      console.log("item", item)
      console.log("index2", index2)
      const datIndex = index2 + 1
      const shopId = item.id
      Loading.show();
      _this.$ajax.get(process.env.VUE_APP_SERVER + 'arrange_yj/re_create/' + shopId + '/' + datIndex).then((response)=>{
        Loading.hide();
        _this.getSettingByShopId(_this.currentId);
        _this.getArrangeSimpleListByShopId(_this.currentId)
      })
    },

    deleteDay (item, index2) {
      const _this = this;
      const datIndex = index2 + 1
      const shopId = item.id
      Loading.show();
      _this.$ajax.get(process.env.VUE_APP_SERVER + 'arrange_yj/delete/' + shopId + '/' + datIndex).then((response)=>{
        Loading.hide();
        _this.getSettingByShopId(_this.currentId);
        _this.getArrangeSimpleListByShopId(_this.currentId)
      })
    },

    handleCheckAllChange(val) {
      this.checkedWeekDays = val ? this.all_week_day_checked : [];
      this.isIndeterminate = false;
    },

    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.all_week_day_checked.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.all_week_day_checked.length;
    },

    saveSetting (id) {
      const _this = this;
      _this.arrangeSettingSbData.yj_id = id
      _this.arrangeSettingSbData.checked_week_days = this.checkedWeekDays
      Loading.show();
      _this.$ajax.post(process.env.VUE_APP_SERVER + 'arrange_yj/save_setting', _this.arrangeSettingSbData).then((response)=>{
        Loading.hide();
        let resp = response.data;
        console.log(resp);
        if (resp.success) {
          console.log("获取结果：", resp.content);
          Toast.success("配置成功！");
        } else {
          // console.log(resp.message);
          Toast.warning(resp.message);
        }
        _this.currentActive = -1;
        _this.list()
      })
      // save_setting
    },
  }
}
</script>
