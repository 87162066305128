import Vue from "vue"
import Router from "vue-router"
import Login from "./views/login.vue"
import Admin from "./views/admin.vue"
import Welcome from "./views/admin/welcome.vue"
import Banner from "./views/admin/banner.vue"
import User from "./views/admin/user.vue"
import Resource from "./views/admin/resource.vue"
import Role from "./views/admin/role.vue"
import Sms from "./views/admin/sms.vue"
import Category from "./views/admin/category"
import Section from "./views/admin/section.vue"
import Doctor from "./views/admin/doctor.vue"
import Advise from "./views/admin/advise.vue"
import Contact from "./views/admin/contact.vue"
import Arrange from "./views/admin/arrange"
import VideoMeeting from "./views/admin/meeting"
import Message from "./views/admin/message"
import Medicine from "./views/admin/medicine"
import Setting from "./views/admin/setting"
import Mzuser from "./views/admin/mzuser"
import Zyuser from "./views/admin/zyuser"
import PayLog from "./views/admin/paylog"
import Order from "./views/admin/order"
import ZyPayMoney from "@/views/admin/zypaymoney";

import ShopUser from "./views/admin/dmj/shopuser"
import Shop from "@/views/admin/dmj/shop";
import ArrangeSb from "@/views/admin/dmj/arrangesb"
import ShopOrder from "@/views/admin/dmj/shoporder"
import ShopUserAdmin from "@/views/admin/dmj/shopuseradmin"

import YjUser from "./views/admin/yj/yjuser"
import Yj from "@/views/admin/yj/yj";
import ArrangeYj from "@/views/admin/yj/arrangeyj"
import YjOrder from "@/views/admin/yj/yjorder"
import YjUserAdmin from "@/views/admin/yj/yjuseradmin"
import YjRecommender from "@/views/admin/yj/yjrecommender"

import Food from "./views/admin/food/food";
import FoodUserAdmin from "./views/admin/food/useradmin"
import FoodOrder from "./views/admin/food/order"
import FoodUser from "./views/admin/food/user"


Vue.use(Router);

export default new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [{
        path: "*",
        redirect: "/login",
    }, {
        path: "",
        redirect: "/login",
    }, {
        path: "/login",
        component: Login
    }, {
        path: "/",
        name: "admin",
        component: Admin,
        meta: {
            loginRequire: true
        },
        children: [{
            path: "welcome",
            name: "welcome",
            component: Welcome,
        }, {
            path: "system/user",
            name: "system/user",
            component: User,
        }, {
            path: "system/resource",
            name: "system/resource",
            component: Resource,
        }, {
            path: "system/role",
            name: "system/role",
            component: Role,
        }, {
            path: "business/banner",
            name: "business/banner",
            component: Banner,
        }, {
            path: "business/sms",
            name: "business/sms",
            component: Sms,
        }, {
            path: "business/category",
            name: "business/category",
            component: Category,
        }, {
            path: "business/section",
            name: "business/section",
            component: Section,
        }, {
            path: "business/doctor",
            name: "business/doctor",
            component: Doctor,
        }, {
            path: "business/advise",
            name: "business/advise",
            component: Advise,
        }, {
            path: "business/contact",
            name: "business/contact",
            component: Contact,
        }, {
            path: "business/meeting",
            name: "business/meeting",
            component: VideoMeeting,
        }, {
            path: "business/message",
            name: "business/message",
            component: Message,
        }, {
            path: "business/medicine",
            name: "business/medicine",
            component: Medicine,
        }, {
            path: "business/mzuser",
            name: "business/mzuser",
            component: Mzuser,
        }, {
            path: "business/zyuser",
            name: "business/zyuser",
            component: Zyuser,
        }, {
            path: "business/shopuser",
            name: "business/shopuser",
            component: ShopUser,
        }, {
            path: "yj/user",
            name: "yj/user",
            component: YjUser,
        }, {
            path: "business/paylog",
            name: "business/paylog",
            component: PayLog,
        }, {
            path: "business/order",
            name: "business/order",
            component: Order,
        }, {
            path: "business/shop",
            name: "business/shop",
            component: Shop,
        }, {
            path: "yj/yj",
            name: "yj/yj",
            component: Yj,
        }, {
            path: "business/shoporder",
            name: "business/shoporder",
            component: ShopOrder,
        }, {
            path: "yj/order",
            name: "yj/order",
            component: YjOrder,
        }, {
            path: "yj/recommender",
            name: "yj/recommender",
            component: YjRecommender,
        }, {
            path: "business/shopuseradmin",
            name: "business/shopuseradmin",
            component: ShopUserAdmin,
        }, {
            path: "yj/useradmin",
            name: "yj/useradmin",
            component: YjUserAdmin,
        }, {
            path: "setting/arrange",
            name: "setting/arrange",
            component: Arrange,
        }, {
            path: "setting/zypaymoney",
            name: "setting/zypaymoney",
            component: ZyPayMoney,
        }, {
            path: "setting/arrangesb",
            name: "setting/arrangesb",
            component: ArrangeSb,
        }, {
            path: "yj/arrange",
            name: "yj/arrange",
            component: ArrangeYj,
        }, {
            path: "setting/setting",
            name: "setting/setting",
            component: Setting,
        }, {
            path: "food/food",
            name: "food/food",
            component: Food,
        }, {
            path: "food/useradmin",
            name: "food/useradmin",
            component: FoodUserAdmin,
        }, {
            path: "food/order",
            name: "food/order",
            component: FoodOrder,
        }, {
            path: "food/user",
            name: "food/user",
            component: FoodUser,
        }]
    }]
})
