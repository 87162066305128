<template>
  <div>
<!--    <p>-->
<!--      <button v-on:click="list()" class="btn btn-white btn-default btn-round">-->
<!--        <i class="ace-icon fa fa-refresh"></i>-->
<!--        刷新-->
<!--      </button>-->
<!--    </p>-->

    <div class="row" style="margin-top: 100px;">
      <div class="col-xs-12">
        <form class="form-horizontal" role="form">
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right" for="form-field-1"> 电话号码1 </label>
            <div class="col-sm-9">
              <input type="text" id="form-field-1" placeholder="电话号码1" v-model="setting_items.phone1" class="col-xs-10 col-sm-5" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right" for="form-field-2"> 电话号码2 </label>
            <div class="col-sm-9">
              <input type="text" id="form-field-2" placeholder="电话号码2" v-model="setting_items.phone2" class="col-xs-10 col-sm-5" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right" for="form-field-2"> 预约咨询电话 </label>
            <div class="col-sm-9">
              <input type="text" id="form-field-10" placeholder="预约咨询电话" v-model="setting_items.phone3" class="col-xs-10 col-sm-5" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right" for="form-field-3"> 群二维码 </label>
            <div class="col-sm-9">
              <file v-bind:input-id="'qun-qrcode-file-upload'"
                    v-bind:text="'上传文件'"
                    v-bind:suffixs="['jpg', 'jpeg', 'png']"
                    v-bind:use="FILE_USE.COURSE.key"
                    v-bind:after-upload="afterUploadQunQrcodeFile"></file>
              <input id="form-field-3" v-model="setting_items.qun_qrcode" type="text" class="col-xs-12 col-sm-5">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right" for="form-field-4"> 邮箱 </label>
            <div class="col-sm-9">
              <input type="text" id="form-field-4" placeholder="邮箱" v-model="setting_items.email" class="col-xs-10 col-sm-5" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right" for="form-field-5"> 地址 </label>
            <div class="col-sm-9">
              <input type="text" id="form-field-5" placeholder="地址" v-model="setting_items.address" class="col-xs-10 col-sm-5" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right" for="form-field-6"> 医院LOGO </label>
            <div class="col-sm-9">
              <file v-bind:input-id="'logo-file-upload'"
                    v-bind:text="'上传文件'"
                    v-bind:suffixs="['jpg', 'jpeg', 'png']"
                    v-bind:use="FILE_USE.COURSE.key"
                    v-bind:after-upload="afterLogoQrcodeFile"></file>
              <input type="text" id="form-field-6" placeholder="地址" v-model="setting_items.logo" class="col-xs-10 col-sm-5" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right" for="form-field-7"> 首页督脉灸入口图 </label>
            <div class="col-sm-9">
              <file v-bind:input-id="'home_advertisement1-file-upload'"
                    v-bind:text="'上传文件'"
                    v-bind:suffixs="['jpg', 'jpeg', 'png']"
                    v-bind:use="FILE_USE.COURSE.key"
                    v-bind:after-upload="afterHomeAdvertisement1File"></file>
              <input type="text" id="form-field-7" placeholder="首页督脉灸入口图" v-model="setting_items.home_advertisement1" class="col-xs-10 col-sm-5" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right" for="form-field-8"> 首页孕检入口图 </label>
            <div class="col-sm-9">
              <file v-bind:input-id="'home_advertisement2-file-upload'"
                    v-bind:text="'上传文件'"
                    v-bind:suffixs="['jpg', 'jpeg', 'png']"
                    v-bind:use="FILE_USE.COURSE.key"
                    v-bind:after-upload="afterHomeAdvertisement2File"></file>
              <input type="text" id="form-field-8" placeholder="首页孕检入口图" v-model="setting_items.home_advertisement2" class="col-xs-10 col-sm-5" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right" for="form-field-4"> 预约成功文字提醒 </label>
            <div class="col-sm-9">
              <input type="text" id="form-field-12" placeholder="提前几分钟到院" v-model="setting_items.advance_time_to_hospital" class="col-xs-10 col-sm-5" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right"> 联系我们管理员号码 </label>
            <div class="col-sm-9">
              <input type="text" id="form-field-12" placeholder="提前几分钟到院" v-model="setting_items.contact_us_phones" class="col-xs-10 col-sm-5" />
            </div>
          </div>
<!--          <div class="form-group">-->
<!--            <label class="col-sm-3 control-label no-padding-right" for="form-field-5"> 视频探视开始时间 </label>-->
<!--            <div class="col-sm-9" style="display: flex; flex-direction: row; align-items: center;">-->
<!--              <input type="text" id="form-field-8" placeholder="视频探视开始时间" v-model="setting_items.video_meeting_day_start" class="col-xs-2 col-sm-1" /><span style="margin-left: 6px;">点</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="form-group">-->
<!--            <label class="col-sm-3 control-label no-padding-right" for="form-field-5"> 视频探视结束时间 </label>-->
<!--            <div class="col-sm-9" style="display: flex; flex-direction: row; align-items: center;">-->
<!--              <input type="text" id="form-field-9" placeholder="视频探视结束时间" v-model="setting_items.video_meeting_day_end" class="col-xs-2 col-sm-1" /><span style="margin-left: 6px;">点</span>-->
<!--            </div>-->
<!--          </div>-->
          <div class="clearfix form-actions">
            <div class="col-md-offset-3 col-md-9">
              <button class="btn btn-info" type="button" @click="save()">
                <i class="ace-icon fa fa-check bigger-110"></i>
                保存
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import File from "../../components/file";
export default {
  components: {File},
  name: "business-setting",
  data: function() {
    return {
      FILE_USE: FILE_USE,
      setting_items: {},
    }
  },
  mounted: function() {
    let _this = this;
    _this.findAllByKeys();
  },
  methods: {
    /**
     * 查找是否有权限
     * @param id
     */
    hasResource(id) {
      return Tool.hasResource(id);
    },

    /**
     * 列表查询
     */
    findAllByKeys() {
      let _this = this;
      Loading.show();
      _this.$ajax.get(process.env.VUE_APP_SERVER + 'setting/find_all_by_keys').then((response)=>{
        console.log("查询结果：", response);
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          _this.setting_items = resp.content;
        }
      })
    },
    afterUploadQunQrcodeFile (response) {
      console.log("开始保存文件记录: ", response);
      this.setting_items.qun_qrcode = response[0].path;
    },
    afterLogoQrcodeFile (response) {
      console.log("开始保存文件记录: ", response);
      this.setting_items.logo = response[0].path;
    },
    afterHomeAdvertisement1File (response) {
      console.log("开始保存文件记录: ", response);
      this.setting_items.home_advertisement1 = response[0].path;
    },
    afterHomeAdvertisement2File (response) {
      console.log("开始保存文件记录: ", response);
      this.setting_items.home_advertisement2 = response[0].path;
    },
    save () {
      let _this = this;
      Loading.show();
      _this.$ajax.post(process.env.VUE_APP_SERVER + 'setting/save', _this.setting_items).then((response)=>{
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          _this.findAllByKeys(1);
          Toast.success("保存成功！");
        } else {
          Toast.warning(resp.message)
        }
      })
    }

  }
}
</script>
