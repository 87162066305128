<template>
  <div>
    <div style="margin-bottom: 10px; display: flex; flex-direction: row; align-items: center;">
      <button v-show="hasResource('040602')" v-on:click="add()" class="btn btn-white btn-default btn-round" style="margin-right: 10px;">
        <i class="ace-icon fa fa-edit"></i>
        新增
      </button>
      <button v-on:click="refreshData" class="btn btn-white btn-default btn-round" style="margin-right: 16px;">
        <i class="ace-icon fa fa-refresh"></i>
        刷新
      </button>
      姓名：<input v-model="name" class="form-control" style="width: 100px; margin-right: 10px;">
      <button v-on:click="list(1)" class="btn btn-white btn-default btn-round">
        <i class="ace-icon fa fa-refresh"></i>
        检索
      </button>
    </div>

    <table id="simple-table" class="table  table-bordered table-hover">
      <thead>
      <tr>
        <th>id</th>
        <th>姓名</th>
        <th>注册时间</th>
        <th>是否启用</th>
        <th>排序</th>
        <th>操作</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="user in users">
        <td>{{user.id}}</td>
        <td>{{user.name}}</td>
        <td>{{user.create_time}}</td>
        <td>
          <div v-if="user.status">
             是
          </div>
          <div v-else>
            否
          </div>
        </td>
        <td>
          {{ user.sort }}
        </td>
        <td>
          <div class="hidden-sm hidden-xs btn-group">
            <button v-on:click="edit(user)" class="btn btn-xs btn-info">
              <i class="ace-icon fa fa-pencil bigger-120"></i>
              修改
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <pagination ref="pagination" v-bind:list="list" v-bind:itemCount="8"></pagination>

    <div id="form-modal" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">添加推荐人</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal">
              <div class="form-group">
                <label class="col-sm-2 control-label">姓名</label>
                <div class="col-sm-10">
                  <input v-model="user.name" class="form-control">
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">状态</label>
                <div class="col-sm-10" style="margin-top: 6px;">
                  <label>
                    启用
                    <input name="status" v-model="user.status" type="radio" value="1" />
                  </label>
                  <label style="margin-left: 20px;">
                    禁用
                    <input name="status" v-model="user.status" type="radio" value="0" />
                  </label>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">排序</label>
                <div class="col-sm-10" style="margin-top: 6px;">
                  <el-input-number v-model="user.sort" :min="1" :max="10000" label="排序"></el-input-number>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
            <button v-on:click="save()" type="button" class="btn btn-primary">保存</button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
  </div>
</template>

<script>
  import Pagination from "../../../components/pagination";
  export default {
    components: {Pagination},
    name: "yj-user",
    data: function() {
      return {
        user: {},
        users: [],
        name: '',
      }
    },
    mounted: function() {
      let _this = this;
      _this.$refs.pagination.size = 20;
      _this.list(1);
    },
    methods: {
      /**
       * 查找是否有权限
       * @param id
       */
      hasResource(id) {
        return Tool.hasResource(id);
      },

      // 更新数据
      refreshData () {
        this.list(1);
      },

      /**
       * 列表查询
       */
      list(page) {
        let _this = this;
        Loading.show();
        _this.$ajax.post(process.env.VUE_APP_SERVER + 'yj_recommender/list', {
          page: page,
          size: _this.$refs.pagination.size,
          name: _this.name
        }).then((response)=>{
          // console.log("列表查询结果：", response);
          Loading.hide();
          let resp = response.data;
          if (resp.success) {
            _this.users = resp.content.list;
            _this.$refs.pagination.render(page, resp.content.total);
          }
        })
      },

      /**
       * 点击【新增】
       */
      add() {
        let _this = this;
        _this.user = {};
        $("#form-modal").modal("show");
      },

      edit (user) {
        let _this = this;
        _this.user = $.extend({}, user);
        $("#form-modal").modal("show");
      },

      save () {
        let _this = this;
        Loading.show();
        _this.$ajax.post(process.env.VUE_APP_SERVER + 'yj_recommender/save', _this.user).then((response)=>{
          Loading.hide();
          this.user = {
            id: -1,
            status: 0
          }
          let resp = response.data;
          $("#form-modal").modal("hide");
          _this.list(1);
          _this.shopList();
          if (resp.success) {
            Toast.success("保存成功！");
          } else {
            Toast.success("保存失败！");
          }
        })
      }
    }
  }
</script>
