<template>
  <div>
    <p>
      <button v-show="hasResource('030201')" v-on:click="add()" class="btn btn-white btn-default btn-round" style="margin-right: 10px;">
        <i class="ace-icon fa fa-edit"></i>
        新增
      </button>
      <button v-on:click="list()" class="btn btn-white btn-default btn-round">
        <i class="ace-icon fa fa-refresh"></i>
        刷新
      </button>
    </p>

    <table id="simple-table" class="table  table-bordered table-hover">
      <thead>
      <tr>
        <th>id</th>
        <th>标题</th>
        <th>充值金额（元）</th>
        <th>状态</th>
        <th>排序</th>
        <th>操作</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="item in doctor_items">
        <td>{{item.id}}</td>
        <td>{{item.title}}</td>
        <td>{{item.price}}</td>
        <td>
          <div v-if="item.status == 1">
            启用
          </div>
          <div v-else-if="item.status == 0">
            禁用
          </div>
          <div v-else>
            未知
          </div>
        </td>
        <td>{{item.sort}}</td>
        <td>
          <div class="hidden-sm hidden-xs btn-group">
            <button v-show="hasResource('030202')" v-on:click="edit(item)" class="btn btn-xs btn-info">
              <i class="ace-icon fa fa-pencil bigger-120"></i>
            </button>
            <button v-show="hasResource('030203')" v-on:click="del(item.id)" class="btn btn-xs btn-danger">
              <i class="ace-icon fa fa-trash-o bigger-120"></i>
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

    <div id="form-modal" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" v-if="doctor_item.id == null">新增</h4>
            <h4 class="modal-title" v-else>编辑</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal">
              <div class="form-group">
                <label class="col-sm-2 control-label">标题</label>
                <div class="col-sm-10">
                  <div style="display: flex; flex-direction: row;">
                    <input v-model="doctor_item.title" class="form-control">
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">充值金额（元）</label>
                <div class="col-sm-10">
                  <input v-model="doctor_item.price" class="form-control">
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">状态</label>
                <div class="col-sm-10">
                  <label>
                    禁用
                    <input name="is_recommend" v-model="doctor_item.status" type="radio" value="0" />
                  </label>
                  <label style="margin-left: 20px;">
                    启用
                    <input name="is_recommend" v-model="doctor_item.status" type="radio" value="1" />
                  </label>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">排序</label>
                <div class="col-sm-10">
                  <input v-model="doctor_item.sort" class="form-control">
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
            <button v-on:click="save()" type="button" class="btn btn-primary">保存</button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
  </div>
</template>

<script>
import File from "../../components/file";
export default {
  components: {File},
  name: "business-zypaymoney",
  data: function() {
    return {
      FILE_USE: FILE_USE,
      doctor_item: {},
      doctor_items: [],
      section_items: [],
      staff_items: []
    }
  },
  mounted: function() {
    let _this = this;
    _this.list();
    // sidebar激活样式方法一
    // this.$parent.activeSidebar("system-user-sidebar");
  },
  methods: {
    /**
     * 查找是否有权限
     * @param id
     */
    hasResource(id) {
      return Tool.hasResource(id);
    },

    /**
     * 点击【新增】
     */
    add() {
      let _this = this;
      _this.doctor_item = {};
      $("#form-modal").modal("show");
    },

    /**
     * 点击【编辑】
     */
    edit(doctor_item) {
      console.log("doctor_item:", doctor_item);
      let _this = this;
      _this.doctor_item = $.extend({}, doctor_item);
      $("#form-modal").modal("show");
    },

    /**
     * 列表查询
     */
    list() {
      let _this = this;
      Loading.show();
      _this.$ajax.get(process.env.VUE_APP_SERVER + 'invest_money_type/list').then((response)=>{
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          _this.doctor_items = resp.content;
        }
      })
    },

    /**
     * 点击【保存】
     */
    save() {
      let _this = this;
      Loading.show();
      _this.$ajax.post(process.env.VUE_APP_SERVER + 'invest_money_type/save', _this.doctor_item).then((response)=>{
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          $("#form-modal").modal("hide");
          _this.list(1);
          Toast.success("保存成功！");
        } else {
          Toast.warning(resp.message)
        }
      })
    },

    /**
     * 点击【删除】
     */
    del(id) {
      let _this = this;
      Confirm.show("删除医生后不可恢复，确认删除？", function () {
        Loading.show();
        _this.$ajax.delete(process.env.VUE_APP_SERVER + 'invest_money_type/delete/' + id).then((response)=>{
          Loading.hide();
          let resp = response.data;
          if (resp.success) {
            _this.list(1);
            Toast.success("删除成功！");
          }
        })
      });
    },
  }
}
</script>
