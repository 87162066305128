<template>
  <div>
    <div style="margin-bottom: 10px; display: flex; flex-direction: row; align-items: center;">
      <button v-on:click="refreshData" class="btn btn-white btn-default btn-round" style="margin-right: 20px;">
        <i class="ace-icon fa fa-refresh"></i>
        刷新
      </button>
      姓名：<input v-model="realname" class="form-control" style="width: 100px; margin-right: 10px;">
      手机号：<input v-model="phone" class="form-control" style="width: 100px; margin-right: 10px;">
      <button v-on:click="list(1)" class="btn btn-white btn-default btn-round">
        <i class="ace-icon fa fa-refresh"></i>
        检索
      </button>
    </div>

    <table id="simple-table" class="table  table-bordered table-hover">
      <thead>
      <tr>
        <th>id</th>
        <th>id账号</th>
        <th>姓名</th>
        <th>性别</th>
        <th>手机号</th>
        <th>微信openid</th>
        <th>ms_brda_id</th>
        <th>注册时间</th>
        <th>操作</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="user in users">
        <td>{{user.id}}</td>
        <td>{{user.id_account}}</td>
        <td>{{user.realname}}</td>
        <td>
          <span v-if="user.sex === 1">男</span>
          <span v-else-if="user.sex === 2">女</span>
          <span v-else>未知</span>
        </td>
        <td>{{user.phone}}</td>
        <td>{{user.openid}}</td>
        <td>{{user.ms_brda_id}}</td>
        <td>{{user.create_time}}</td>
        <td>
          <div class="hidden-sm hidden-xs btn-group">

          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <pagination ref="pagination" v-bind:list="list" v-bind:itemCount="8"></pagination>

  </div>
</template>

<script>
  import Pagination from "../../components/pagination";
  export default {
    components: {Pagination},
    name: "system-mzuser",
    data: function() {
      return {

        user: {},
        users: [],
        realname: '',
        phone: '',
      }
    },
    mounted: function() {
      let _this = this;
      _this.$refs.pagination.size = 20;
      _this.list(1);
      // sidebar激活样式方法一
      // this.$parent.activeSidebar("system-user-sidebar");
    },
    methods: {
      /**
       * 查找是否有权限
       * @param id
       */
      hasResource(id) {
        return Tool.hasResource(id);
      },

      // 更新数据
      refreshData () {
        this.list(1);
        this.shopList();
      },

      /**
       * 列表查询
       */
      list(page) {
        let _this = this;
        Loading.show();
        _this.$ajax.post(process.env.VUE_APP_SERVER + 'mz_user/list', {
          page: page,
          size: _this.$refs.pagination.size,
          realname: _this.realname,
          phone: _this.phone
        }).then((response)=>{
          console.log("列表查询结果：", response);
          Loading.hide();
          let resp = response.data;
          if (resp.success) {
            _this.users = resp.content.list;
            _this.$refs.pagination.render(page, resp.content.total);
          }
        })
      },
    }
  }
</script>
