<template>
  <div>
    <p>
      <button v-on:click="list()" class="btn btn-white btn-default btn-round">
        <i class="ace-icon fa fa-refresh"></i>
        刷新
      </button>
    </p>

    <table id="simple-table" class="table  table-bordered table-hover">
      <thead>
      <tr>
        <th>id</th>
        <th>就诊人</th>
        <th>电话</th>
        <th>预约id</th>
        <th>中药帖数</th>
        <th>是否煎好</th>
        <th>订阅消息</th>
        <th>是否已读</th>
        <th>操作</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="item in medicine_items">
        <td>{{item.id}}</td>
        <td>{{item.username}}</td>
        <td>{{item.phone}}</td>
        <td>{{item.order_id}}</td>
        <td>{{item.num}}</td>
        <td>
          <div v-if="item.has_ready == 1">已煎好</div>
          <div v-else>未煎好</div>
        </td>
        <td>
          <div v-if="item.has_send_message == 1">已发送</div>
          <div v-else>未发送</div>
        </td>
        <td>
          <div v-if="item.has_read == 1">已读</div>
          <div v-else>未读</div>
        </td>
        <td>
          <div class="hidden-sm hidden-xs btn-group">
            <button v-show="hasResource('020902') && item.has_ready === 0" v-on:click="edit(item)" class="btn btn-xs btn-info">
              <i class="ace-icon fa fa-pencil bigger-120"></i>模拟煎好
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import File from "../../components/file";
export default {
  components: {File},
  name: "business-medicine",
  data: function() {
    return {
      FILE_USE: FILE_USE,
      medicine_item: {},
      medicine_items: [],
    }
  },
  mounted: function() {
    let _this = this;
    _this.list();
    // sidebar激活样式方法一
    // this.$parent.activeSidebar("system-user-sidebar");
  },
  methods: {
    /**
     * 查找是否有权限
     * @param id
     */
    hasResource(id) {
      return Tool.hasResource(id);
    },

    /**
     * 点击【编辑】
     */
    edit(medicine_item) {
      // console.log("medicine_item:", medicine_item);
      let _this = this;
      Loading.show();
      medicine_item.has_medicine = 1;
      medicine_item.has_ready = 1;
      _this.$ajax.post(process.env.VUE_APP_SERVER + 'ch_medicine/save', medicine_item).then((response)=>{
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          $("#form-modal").modal("hide");
          _this.list(1);
          Toast.success("保存成功！");
        } else {
          Toast.warning(resp.message)
        }
      })
    },

    /**
     * 列表查询
     */
    list() {
      let _this = this;
      Loading.show();
      _this.$ajax.get(process.env.VUE_APP_SERVER + 'ch_medicine/list').then((response)=>{
        console.log("列表查询结果：", response);
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          _this.medicine_items = resp.content;
        }
      })
    }
  }
}
</script>
