<template>
  <div>
    <p>
      <button v-show="hasResource('050102')" v-on:click="add()" class="btn btn-white btn-default btn-round" style="margin-right: 10px;">
        <i class="ace-icon fa fa-edit"></i>
        新增
      </button>
      <button v-on:click="list()" class="btn btn-white btn-default btn-round">
        <i class="ace-icon fa fa-refresh"></i>
        刷新
      </button>
    </p>

    <table id="simple-table" class="table  table-bordered table-hover">
      <thead>
      <tr>
        <th>id</th>
        <th>菜名</th>
        <th>价格</th>
        <th>排序</th>
        <th>分类</th>
        <th>状态</th>
        <th>操作</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="item in shop_items">
        <td>{{item.id}}</td>
        <td>{{item.name}}</td>
        <td>{{item.price}}</td>
        <td>{{item.sort}}</td>
        <td>
          <div v-if="item.food_cate_id == 1">
            素菜
          </div>
          <div v-if="item.food_cate_id == 2">
            荤菜
          </div>
          <div v-if="item.food_cate_id == 3">
            鱼
          </div>
          <div v-if="item.food_cate_id == 4">
            汤
          </div>
          <div v-if="item.food_cate_id == 5">
            主食
          </div>
        </td>
        <td>
          <div v-if="item.status == 1">
            启用
          </div>
          <div v-else>
            禁用
          </div>
        </td>
        <td>
          <div class="hidden-sm hidden-xs btn-group">
            <button v-show="hasResource('050102')" v-on:click="edit(item)" class="btn btn-xs btn-info">
              <i class="ace-icon fa fa-pencil bigger-120"></i>
            </button>
<!--            <button v-show="hasResource('040103')" v-on:click="del(item.id)" class="btn btn-xs btn-danger">-->
<!--              <i class="ace-icon fa fa-trash-o bigger-120"></i>-->
<!--            </button>-->
          </div>
        </td>
      </tr>
      </tbody>
    </table>

    <div id="form-modal" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" v-if="shop_item.id == null">新增菜品</h4>
            <h4 class="modal-title" v-else>编辑菜品</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal">
              <div class="form-group">
                <label class="col-sm-2 control-label">名称</label>
                <div class="col-sm-10">
                  <input v-model="shop_item.name" class="form-control">
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">排序</label>
                <div class="col-sm-10" style="margin-top: 6px;">
                  <el-input-number v-model="shop_item.sort" :min="1" :max="10000" label="排序"></el-input-number>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">价格</label>
                <div class="col-sm-10">
                  <input v-model="shop_item.price" class="form-control">
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">分类</label>
                <div class="col-sm-10" style="line-height: 36px; display: flex; flex-direction: row;">
                  <select v-model="shop_item.food_cate_id" style="margin-top: 4px; width: 100px;" class="form-control">
                    <option v-bind:value="1">素菜</option>
                    <option v-bind:value="2">荤菜</option>
                    <option v-bind:value="3">鱼</option>
                    <option v-bind:value="4">汤</option>
                    <option v-bind:value="5">主食</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">状态</label>
                <div class="col-sm-10" style="line-height: 36px; display: flex; flex-direction: row;">
                  <select v-model="shop_item.status" style="margin-top: 4px; width: 100px;" class="form-control">
                    <option v-bind:value="0">禁用</option>
                    <option v-bind:value="1">启用</option>
                  </select>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
            <button v-on:click="save()" type="button" class="btn btn-primary">保存</button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
  </div>
</template>

<script>
import File from "../../../components/file";
  export default {
    components: {File},
    name: "food-food",
    data: function() {
      return {
        FILE_USE: FILE_USE,
        shop_item: {},
        shop_items: [],
      }
    },
    mounted: function() {
      let _this = this;
      _this.list();
      // sidebar激活样式方法一
      // this.$parent.activeSidebar("system-user-sidebar");
    },
    methods: {
      /**
       * 查找是否有权限
       * @param id
       */
      hasResource(id) {
        return Tool.hasResource(id);
      },

      /**
       * 点击【新增】
       */
      add() {
        let _this = this;
        _this.shop_item = {};
        $("#form-modal").modal("show");
      },

      /**
       * 点击【编辑】
       */
      edit(shop_item) {
        let _this = this;
        _this.shop_item = $.extend({}, shop_item);
        $("#form-modal").modal("show");
      },

      /**
       * 列表查询
       */
      list() {
        let _this = this;
        Loading.show();
        _this.$ajax.get(process.env.VUE_APP_SERVER + 'food/list').then((response)=>{
          console.log("孕检点列表查询结果：", response);
          Loading.hide();
          let resp = response.data;
          if (resp.success) {
            _this.shop_items = resp.content;
          }
        })
      },

      /**
       * 点击【保存】
       */
      save() {
        let _this = this;
        // 保存校验
        Loading.show();
        _this.$ajax.post(process.env.VUE_APP_SERVER + 'food/save', _this.shop_item).then((response)=>{
          Loading.hide();
          let resp = response.data;
          if (resp.success) {
            $("#form-modal").modal("hide");
            _this.list(1);
            Toast.success("保存成功！");
          } else {
            Toast.warning(resp.message)
          }
        })
      },

      /**
       * 点击【删除】
       */
      del(id) {
        let _this = this;
        Confirm.show("删除孕检点后不可恢复，确认删除？", function () {
          Loading.show();
          _this.$ajax.delete(process.env.VUE_APP_SERVER + 'food/delete/' + id).then((response)=>{
            Loading.hide();
            let resp = response.data;
            if (resp.success) {
              _this.list(1);
              Toast.success("删除成功！");
            }
          })
        });
      },
    }
  }
</script>
