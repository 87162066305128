<template>
  <div>
    <p>
      <button v-on:click="list(1)" class="btn btn-white btn-default btn-round">
        <i class="ace-icon fa fa-refresh"></i>
        刷新
      </button>
    </p>

    <table id="simple-table" class="table  table-bordered table-hover">
      <thead>
      <tr>
        <th>id</th>
        <th>姓名</th>
        <th>手机号</th>
        <th>预约时段</th>
        <th>订单创建时间</th>
        <th>订单状态</th>
        <th>操作</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="item in shop_order_items">
        <td>{{item.id}}</td>
        <td>{{item.username}}</td>
        <td>{{item.phone}}</td>
        <td>{{item.order_day_str + " " + (item.day_cate == 1 ? "午餐" : "晚餐")}}</td>
        <td>{{item.create_time}}</td>
        <td>
          <div v-if="item.status == 1">
            预约成功
          </div>
          <div v-if="item.status == -10">
            管理员取消预约
          </div>
          <div v-if="item.status == -9">
            用户取消预约
          </div>
        </td>
        <td>
          <div class="hidden-sm hidden-xs btn-group">
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <pagination ref="pagination" v-bind:list="list" v-bind:itemCount="8"></pagination>

  </div>
</template>

<script>
import Pagination from "../../../components/pagination";
  export default {
    components: {Pagination},
    name: "yj-order",
    data: function() {
      return {
        shop_order_item: {},
        shop_order_items: [],
      }
    },
    mounted: function() {
      let _this = this;
      _this.$refs.pagination.size = 20;
      _this.list(1);
      // sidebar激活样式方法一
      // this.$parent.activeSidebar("system-user-sidebar");
    },
    methods: {
      /**
       * 查找是否有权限
       * @param id
       */
      hasResource(id) {
        return Tool.hasResource(id);
      },

      /**
       * 列表查询
       */
      list(page) {
        let _this = this;
        Loading.show();
        _this.$ajax.post(process.env.VUE_APP_SERVER + 'order_food/list', {
          page: page,
          size: _this.$refs.pagination.size
        }).then((response)=>{
          Loading.hide();
          let resp = response.data;
          if (resp.success) {
            _this.shop_order_items = resp.content.list;
            _this.$refs.pagination.render(page, resp.content.total);
          }
        })
      }
    }
  }
</script>
